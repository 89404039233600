import { render, staticRenderFns } from "./pageElementGallery.vue?vue&type=template&id=decb6fbc&scoped=true&"
import script from "./pageElementGallery.vue?vue&type=script&lang=js&"
export * from "./pageElementGallery.vue?vue&type=script&lang=js&"
import style0 from "./pageElementGallery.vue?vue&type=style&index=0&id=decb6fbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "decb6fbc",
  null
  
)

export default component.exports