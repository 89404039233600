<template>
  <div>
    <!-- There are images in the gallery -->
    <template v-for="(galleryItem, index) in item.Value">
      <div
        :key="index"
        v-if="galleryItem.Name != ''"
        :class="needsDisable ? '' : 'imageContainer gallery mb-4'"
      >
        <img
          class="galleryImage"
          :src="
            serverUrl +
            '/images/' +
            websiteId +
            '/' +
            galleryItem.Name +
            '?token=' +
            userToken
          "
        />
        <v-layout row wrap class="editContainer mt-2 mt-2 pl-3 pr-3">
          <template v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin">
            <template v-if="item.AddAltTags || item.AddAltTags == null">
              <template v-if="item.Translate || item.Translate == undefined">
                <v-flex lg3 sm3 xs12 pr-4>
                  <v-select
                    :items="itemLanguages"
                    v-model="itemDefaultLanguage"
                    single-line
                    class="altSelect"
                  ></v-select>
                </v-flex>
              </template>
              <v-flex lg7 sm7 xs10 pr-4>
                <template
                  v-for="(
                    translatedValue, translatedValueIndex
                  ) in galleryItem.Alt"
                >
                  <template v-if="itemDefaultLanguage == translatedValueIndex">
                    <input
                      :key="translatedValueIndex"
                      type="text"
                      class="textInput"
                      placeholder="Alt tag"
                      v-model="galleryItem.Alt[translatedValueIndex]"
                    />
                  </template>
                </template>
              </v-flex>
            </template>
            <v-flex lg2 sm2 xs2>
              <v-btn
                color="error"
                class="customRemoveButton"
                icon
                @click="deleteFile(item.Value, index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-flex>
          </template>
        </v-layout>
      </div>
    </template>
    <div v-if="!uploading">
      <template v-for="(image, index) in this.files">
        <h3 :key="index">
          <v-icon>mdi-image</v-icon>
          {{ image.name }}
          <v-btn color="error" icon @click="deleteSelectedFile(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </h3>
      </template>
    </div>
    <preloader v-else></preloader>

    <input
      v-if="!item.ReadOnly || editItemAdmin"
      :ref="'galleryFileInput' + objectName"
      class="fileInput"
      multiple
      type="file"
      @change="setSelectedFiles($event.target.files)"
    />
    <v-btn
      v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
      class="ml-0 mt-3 mr-3 edit-page-action-button"
      @click="$refs['galleryFileInput' + objectName].click()"
      color="primary"
      >{{ $t("Choose images") }}</v-btn
    >
    <v-btn
      v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
      color="blue-grey"
      class="ml-0 mt-3 white--text edit-page-action-button"
      :disabled="!files || uploading || uploadError.length > 0"
      :loading="uploading"
      @click="submitGalleryFiles(item)"
      >{{ $t("Upload") }}
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>
    <errorHandeling
      v-if="uploadError.length > 0"
      :snackbarText="uploadError"
      :buttons="[
        {
          isText: true,
          functionName: 'reportError',
          text: 'Report error',
        },
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert-circle"
      @clearError="$emit('setUploadError', '')"
    />

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mw-w"
            ></v-text-field>
            <v-switch
              v-model="item.Translate"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Translate item')"
            ></v-switch>
            <v-switch
              v-model="item.AddAltTags"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Add alt tags')"
            ></v-switch>
            <v-switch
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    uploadError: {},
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      files: null,
      uploading: false,
    };
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    deleteFile(files, index) {
      files.splice(index, 1);
    },
    deleteSelectedFile(index) {
      this.files.splice(index, 1);
    },
    setSelectedFiles(fileList) {
      this.uploading = true;
      this.$emit("update:saving", true);
      this.$emit("setUploadError", "");
      this.files = Array.prototype.slice.call(fileList);

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      this.$request.post(
        "/content/fileupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$emit("update:saving", false);
          this.$refs["filesInput" + this.objectName].value = null;

          if (res.success) {
            if (this.item["TranslatedFiles"]) {
              if (this.item.Value[this.itemDefaultLanguage]) {
                this.item.Value[this.itemDefaultLanguage].push(...res.data);
              } else if (!this.item.Value) {
                this.item.Value = {};
                this.item.Value[this.itemDefaultLanguage] = res.data;
              } else {
                this.item.Value[this.itemDefaultLanguage] = {};
                this.item.Value[this.itemDefaultLanguage] = res.data;
              }
            } else {
              if (this.item.Value) {
                this.item.Value = this.item.Value.concat(res.data);
              } else {
                this.item.Value = res.data;
              }
            }
            this.files = null;
          } else {
            this.files = null;
            this.$emit("setUploadError", res.message);
          }
        }
      );
    },
    submitGalleryFiles(item) {
      this.uploading = true;
      this.$emit("update:saving", true);
      this.$emit("setUploadError", "");

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }
      this.$request.post(
        "/content/imageupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$emit("update:saving", false);
          this.$refs["galleryFileInput" + this.objectName].value = null;

          if (res.success) {
            if (item.Value == "") {
              item.Value = [];
            } else {
              item.Value = item.Value.filter((i) => i.Name != "");
            }

            for (let i = 0; i < res.data.length; i++) {
              item.Value[item.Value.length] = {
                Name: res.data[i],
                Alt: { Nl: "", Fr: "", En: "" },
              };
            }
            this.getItemLanguages();
            this.getItemDefaultLanguage();
            this.files = null;
          } else {
            this.$emit("setUploadError", res.message);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.galleryImage {
  max-width: 100%;
  max-height: 350px;
}
.imageContainer {
  position: relative;
  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}
</style>